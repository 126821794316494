import { ReactElement, useEffect, useState } from "react";
import { Button, Image, Header, Flex, Text, Grid, Card } from "@fluentui/react-northstar";
import "./PowerAppsCards.css";
import { pages, app } from "@microsoft/teams-js";
import * as microsoftTeams from "@microsoft/teams-js";

export default function PowerAppsCards(props: { environment?: string }) {

    // Define "useState" React hook to use it to get Teams Current used Theme
    const [TeamsTheme, setTeamsTheme] = useState('');

    // Initialize teams-js to get Current Theme type from app.getContext()
    useEffect(() => {
        if (!app.isInitialized()) {
            app.initialize();
        }
        app.getContext().then((contextInfo) => {
            // Put Current used Teams Theme inside "TeamsTheme" state variable
            setTeamsTheme(contextInfo.app.theme);
            console.log(contextInfo);
        }).catch((err) => {
            console.error("Error getting context -> ", err);
        });

    }, []);


    //get screen width to know if on mobile or not
    const [mobile, setMobile] = useState(window.innerWidth <= 900);

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 900);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    // Static Ctelecoms PowerApps Info (Add Or Change any info you want here)
    const cardsData = [
        // { // Form Demo Tranat for testing
        //     AppId: "592ebb63-cbab-46b0-ad29-c8a2d09af598",
        //     AppName: 'Demooo TopicsJet',
        //     Description: 'Demo Tenant Testing App 11',
        //     IconURL: 'https://pafeblobprodam.blob.core.windows.net:443/20220810t000000z8ede4bd5d52f45caa39b2a78baefc60b/logoSmallFile?sv=2018-03-28&sr=c&sig=xJa0Fi3QY3Fofa0RiJxWvsXhE4fQoMlqJbCQfBUcJdA%3D&se=2023-06-22T18%3A23%3A50Z&sp=rl',
        //     BackgroundColor: 'rgba(171, 153, 78, 1)',
        // },

        // { // Form Demo Tranat for testing
        //     AppId: "3165e605-e8ea-469c-b809-63328bee4924",
        //     AppName: 'Demo Visitor Management Pro',
        //     Description: 'Demo Tenant Testing App 22',
        //     IconURL: 'https://pafeblobprodam.blob.core.windows.net:443/20211211t000000zf61abac570f3472c9f38658a38518ee7/logoSmallFile?sv=2018-03-28&sr=c&sig=MVeK9OhANeEudVXLB7YYmUck3OK5go7uZYLqCuqlyRo%3D&se=2023-06-22T09%3A48%3A47Z&sp=rl',
        //     BackgroundColor: 'rgba(147, 48, 139, 1)',
        // },

        // { // Form Demo Tranat for testing
        //   AppId: "2c5fa8ac-8007-4274-9a6a-1f67900d4006",
        //   AppName: 'Demoo New Comers',
        //   Description: 'Demo Tenant Testing App 33',
        //   IconURL: 'https://pafeblobprodam.blob.core.windows.net:443/20220301t000000zc08d70ed427c4a19bcf99623e9f59675/logoSmallFile?sv=2018-03-28&sr=c&sig=O1pa2zg6WJqYZdsM7xwLyS%2BKco%2BhDClsKjsoVDYLaNk%3D&se=2023-06-22T09%3A48%3A47Z&sp=rl',
        //   BackgroundColor: 'rgba(3, 107, 60, 1)',
        // },

        {
            AppId: "68341c35-d3cc-4862-8e20-6395485ab3e1",
            AppName: 'Allowances Management',
            Description: 'Employees can submit their own overtime and per diem requests',
            IconURL: 'allowances.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "2cf51a16-fff3-42df-a8b8-0fcc7c9bbd39",
            AppName: 'Cash Purchases',
            Description: 'Manage and track cash purchase orders',
            IconURL: 'cashPurchase.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        { // App ID for app inside CTC Production Environment 
            AppId: "97411127-33f0-475c-aa65-e42f1006ddc1",
            AppName: 'Payment Order',
            Description: 'Create and manage payments orders',
            IconURL: 'paymentOrder.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "a6282c23-973e-4576-985e-3df154c2b9ea",
            AppName: 'Leads Scoring',
            Description: 'Manage and track leads',
            IconURL: 'leedsScoring.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "e58f22d4-c1d8-49d7-ba3e-d640ab0c7306",
            AppName: 'Project Tracking System',
            Description: 'Enhance and manage all our projects in each stage',
            IconURL: 'projectTracking.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "67a1a1c6-2bb2-4dae-b611-528876295205",
            AppName: 'Contracts Management',
            Description: 'Simple app for submitting, approving and tracking our contracts with the customers.',
            IconURL: 'ContractsManagement.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },
        
        {
            AppId: "992b81cf-4b07-4023-8328-d91538922d6f",
            AppName: 'Purchases Request',
            Description: 'Easy way to apply for your Purchases Requests and track its progress.',
            IconURL: 'PurchasesRequest.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },
        
        {
            AppId: "4bfd570e-9932-414c-a752-271a4410d494",
            AppName: 'TopicsJet',
            Description: 'Amazing topics you can add it with your teammates',
            IconURL: 'topicsJet.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "2f077736-8208-4a71-bec8-13b62089ff8e",
            AppName: 'Minutes of Meeting',
            Description: 'Internal meeting management with multiple capabilities and options',
            IconURL: 'minutesOfMeeting.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "9554de95-e3e6-4c64-a189-0c693e764708",
            AppName: 'MS Workshops System',
            Description: 'Managing microsoft workshops',
            IconURL: 'workshops.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "895f2538-e9b7-4ad6-8b0c-6253188ca526",
            AppName: 'Warehouser',
            Description: 'Ctelecoms warehouse management for each IT and communication devices',
            IconURL: 'warehouser.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "30181cc3-b891-4f02-95dd-358b0113a5d1",
            AppName: 'SLA Periodic Visits',
            Description: 'Manage technical support periodic visits',
            IconURL: 'sla.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "f69acd79-d383-49b0-bbed-fd6f32aafee2",
            AppName: 'Vacation Request',
            Description: 'Make a vacation requests for remote employees',
            IconURL: 'vacationRequest.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "2c78e94a-c1fb-413a-b206-16595a2a10a2",
            AppName: 'Newcomers',
            Description: 'PowerApp for new employees to make specific tasks',
            IconURL: 'newComers.png',
            BackgroundColor: 'rgba(251, 101, 60, 1)',
        },

        {
            AppId: "28b23c08-5e60-483b-83ca-5cb0caed0bb9",
            AppName: 'Feedback Collector',
            Description: 'Collect customers feedback for a specific product or service',
            IconURL: 'feedbackCollector.png',
            BackgroundColor: 'rgba(64, 64, 65, 1)',
        },

        {
            AppId: "3dfba92d-f4dd-4baf-9a23-1a393c070b6b",
            AppName: 'Document Approval',
            Description: 'PowerApp for approve necessary documents',
            IconURL: 'documentApproval.png',
            BackgroundColor: 'rgba(0, 102, 102, 1)',
        },

        {
            AppId: "7c8a6618-d8fb-490f-a134-c916159ff6d1",
            AppName: 'Document Request',
            Description: 'PowerApp used to request some internally Documents',
            IconURL: 'documentRequest.png',
            BackgroundColor: 'rgba(103, 157, 89, 1)',
        },

        {
            AppId: "82c77ccd-f111-49ac-beec-5638e0a19643",
            AppName: 'Check-ins Admins',
            Description: 'PowerApp for location check-ins for admins',
            IconURL: 'checkinAdmins.png',
            BackgroundColor: 'rgba(141, 75, 128, 1)',
        },

        {
            AppId: "ef6a0329-8ade-4453-896d-66b51ded8ab2",
            AppName: 'Check-ins',
            Description: 'PowerApp for location check-ins for users',
            IconURL: 'checkins.png',
            BackgroundColor: 'rgba(141, 75, 128, 1)',
        },

        {
            AppId: "edf71971-0d6e-4d1d-8433-17d69cb60e09",
            AppName: 'Visitor Management Pro',
            Description: 'Make and manage meetings, online visits',
            IconURL: 'visitorManagement.png',
            BackgroundColor: 'rgba(147, 48, 139, 1)',
        },

        {
            AppId: "dbe1f324-6868-45fa-829d-c3ed50f9d559",
            AppName: 'Inspection',
            Description: 'Workers can use this app to make a new inspections',
            IconURL: 'inspections.png',
            BackgroundColor: 'rgba(150, 115, 86, 1)',
        },

        {
            AppId: "210f2b54-3950-4825-9c22-07e2e00608eb",
            AppName: 'Manage Inspections',
            Description: 'Track and manage all your workers within any facility',
            IconURL: 'manageInspections.png',
            BackgroundColor: 'rgba(150, 115, 86, 1)',
        },

        {
            AppId: "315587d7-caef-494f-ab21-7ba4763681db",
            AppName: 'Idea Share',
            Description: 'Share creative ideas between all your teammates',
            IconURL: 'ideaShare.png',
            BackgroundColor: 'rgba(236, 157, 33, 1)',
        },

        {
            AppId: "7628a75a-65f1-478b-9828-ed94cb1aefd7",
            AppName: 'Manage Issues',
            Description: 'Manage and interact with employees issues in your company',
            IconURL: 'manageIssues.png',
            BackgroundColor: 'rgba(241, 104, 105, 1)',
        },

        {
            AppId: "dc44a0ab-90de-4f36-a889-ec8cdfc7d77b",
            AppName: 'Issue Reporting',
            Description: 'Employees can report for any issue or problem in their work',
            IconURL: 'issueReporting.png',
            BackgroundColor: 'rgba(241, 104, 105, 1)',
        },

    ];

    // ### Trying to Get PowerApps Data from Dataverse using Dataverse API, But there is a problem in Mainfest file because this Project sample doesn't support SSO (Single Sign in Authentication)
    // https://jsonplaceholder.typicode.com/posts
    // https://org462714db.crm4.dynamics.com/api/data/v9.2/ctc_applications?$top=20

    // app.initialize();
    // // Get the authentication token
    // microsoftTeams.authentication.getAuthToken().then(tokenResponse => { //Token Response
    //         console.log(tokenResponse);
    //         return tokenResponse;
    //     })
    //     .then(tokenValue => { //Token Value
    //         console.log(tokenValue);
    //         fetch('https://org462714db.api.crm4.dynamics.com/api/data/v9.2/ctc_applications', {
    //             headers: {
    //                 'Authorization': 'Bearer ' + tokenValue,
    //             },
    //         })
    //         .then(response => { // API Full Response
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //         })
    //         .then(data => { //Dataverse Table Data
    //             console.log(data);
    //         })
    //         .catch(error => {
    //             console.error('There was a problem with the fetch operation:', error);
    //         });

    //     })
    //     .catch(error => {
    //         console.error('There was a problem with the fetch operation:', error);
    //     });

    // fetch('https://jsonplaceholder.typicode.com/posts')
    // .then(response => {
    //     if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //     }
    //     console.log(response);
    //     return response.json();
    // })
    // .then(data => {
    //     console.log(data);
    // })
    // .catch(error => {
    //     console.error('There was a problem with the fetch operation:', error);
    // });

    // async function getData() {
    //     let response = await fetch('https://jsonplaceholder.typicode.com/posts');
    //     if (!response.ok) {
    //         throw new Error('Network response was not ok');
    //     };

    //     let data = await response.json();
    //     console.log(response);
    //     console.log(data);

    // };
    // getData();

    // Card CSS Styling for "Default" Teams Theme && Hover styling will be added here (Note: adding styles as aClass in Css file didn't work for a specific properties) 
    const cardStyles_Default = {
        width: '95%', // to add more gap space between cards in on row
        height: '100%',
        margin: 'auto',
        border: '1px solid #ddd', // Add a black border to the card
        borderRadius: '10px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)', // Add a shadow to the card
        transition: '0.7s',
        cursor: 'pointer',
        ':hover': {
            border: '1px solid #ddd',
            borderRadius: '10px',
            background: '#f5f5f5',
        }
    };

    // Card CSS Styling for "Dark" Teams Theme && Hover styling will be added here (Note: adding styles as aClass in Css file didn't work for a specific properties) 
    const cardStyles_Dark = {
        width: '95%', // to add more gap space between cards in on row
        height: '100%',
        margin: 'auto',
        border: '1px solid #222', // Add a black border to the card
        borderRadius: '10px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)', // Add a shadow to the card
        transition: '0.7s',
        cursor: 'pointer',
        background: '#222222',
        ':hover': {
            border: '1px solid #555',
            borderRadius: '10px',
            background: '#f5f5f5',
        }
    };
    if (mobile) {
        return (
            <>
                <Flex styles={{ padding: '20px', margin: '0 0 20px 0' }} vAlign="center" hAlign="center">
                    {/* <Header 
                    as="h1" 
                    align="start" 
                    content="CTC Center" 
                    color="orange" 
                    styles={{padding: '10px',margin: '0px 0px 30px 0px'}}
                >
                </Header> */}
                    <Image
                        style={{
                            width: '500px',
                            height: '80px',
                        }}
                        src='CtelecomsLogo.png'
                    />
                </Flex>

                <Grid columns={1}>
                    {cardsData.map((cardData) => (
                        // Add Div to allow margin style applied correctly (dont work on card immediatly)
                        // Put in the top level item inside loop a key value that is a unique value to prevent error in console
                        <div style={{ margin: '10px' }} key={cardData.AppId}>
                            <Card styles={TeamsTheme === 'dark' ? cardStyles_Dark : cardStyles_Default}
                                onClick={() => {
                                    // if(microsoftTeams.appInstallDialog.isSupported()){
                                    //     microsoftTeams.appInstallDialog.openAppInstallDialog({appId:cardData.AppId}).then(()=>console.log('linked successfully')).catch(()=> console.log('link failed'));
                                    // }
                                    // else{
                                    //     app.openLink(`https://teams.microsoft.com/l/entity/${cardData.AppId}/${cardData.AppId}`);    
                                    // }
                                    // Deep linking to any PowerApp works in Teams App and browser
                                    app.openLink(`https://teams.microsoft.com/l/entity/${cardData.AppId}/${cardData.AppId}`);
                                    // ** Another way to open PowerApps:
                                    // const baseUrl = `https://${window.location.hostname}:${window.location.port}/index.html/tabdetails`;
                                    // console.log(baseUrl);
                                    // pages.navigateToApp({ appId: cardData.AppId, pageId: cardData.AppId, webUrl: encodeURI(baseUrl) });

                                }}
                                size="large"
                                horizontal
                                aria-roledescription="card with a preview image, text and action buttons"
                            >
                                <Card.Preview horizontal>
                                    <Flex style={{
                                        height: '60px',
                                        width: '60px',
                                        backgroundColor: cardData.BackgroundColor,
                                        borderRadius: '10px'
                                    }}
                                        vAlign="center"
                                        hAlign="center"
                                    >
                                        <Image
                                            style={{
                                                height: '40px',
                                                width: '40px',
                                            }}
                                            src={cardData.IconURL}
                                        />
                                    </Flex>

                                </Card.Preview>
                                {/* TO Make <Flex space="between" work correctly and push "Open" button to the ebd of card we have to let Card.Column component to get the full width */}
                                <Card.Column styles={{ width: '100%' }}>
                                    <Card.Body>
                                        <Flex space="between" vAlign="center">
                                            <Text content={cardData.AppName} weight="bold" size="large" />
                                            <Button
                                                content="Open"
                                                size="small"
                                            />
                                        </Flex>
                                    </Card.Body>
                                    <Card.Body>
                                        <Text content={cardData.Description} />
                                    </Card.Body>
                                    {/* <Card.Footer fitted>
                        <Flex space="between" vAlign="center">
                            <Text content="Category" />
                            <Text timestamp content="Date" />
                        </Flex>
                    </Card.Footer> */}
                                </Card.Column>
                            </Card>
                        </div>
                    )
                    )}
                </Grid>

            </>
        )
    } else {
        return (
            <>
                <Flex styles={{ padding: '20px', margin: '0 0 20px 0' }} vAlign="center" hAlign="center">
                    {/* <Header 
                                as="h1" 
                                align="start" 
                                content="CTC Center" 
                                color="orange" 
                                styles={{padding: '10px',margin: '0px 0px 30px 0px'}}
                            >
                            </Header> */}
                    <Image
                        style={{
                            width: '500px',
                            height: '80px',
                        }}
                        src='CtelecomsLogo.png'
                    />
                </Flex>

                <Grid columns={3}>
                    {cardsData.map((cardData) => (
                        // Add Div to allow margin style applied correctly (dont work on card immediatly)
                        // Put in the top level item inside loop a key value that is a unique value to prevent error in console
                        <div style={{ margin: '10px' }} key={cardData.AppId}>
                            <Card styles={TeamsTheme === 'dark' ? cardStyles_Dark : cardStyles_Default}
                                onClick={() => {
                                    // Deep linking to any PowerApp works in Teams App and browser
                                    app.openLink(`https://teams.microsoft.com/l/entity/${cardData.AppId}/${cardData.AppId}`);
                                    // ** Another way to open PowerApps:
                                    // const baseUrl = `https://${window.location.hostname}:${window.location.port}/index.html/tabdetails`;
                                    // console.log(baseUrl);
                                    // pages.navigateToApp({ appId: cardData.AppId, pageId: cardData.AppId, webUrl: encodeURI(baseUrl) });

                                }}
                                size="large"
                                horizontal
                                aria-roledescription="card with a preview image, text and action buttons"
                            >
                                <Card.Preview horizontal>
                                    <Flex style={{
                                        height: '60px',
                                        width: '60px',
                                        backgroundColor: cardData.BackgroundColor,
                                        borderRadius: '10px'
                                    }}
                                        vAlign="center"
                                        hAlign="center"
                                    >
                                        <Image
                                            style={{
                                                height: '40px',
                                                width: '40px',
                                            }}
                                            src={cardData.IconURL}
                                        />
                                    </Flex>

                                </Card.Preview>
                                {/* TO Make <Flex space="between" work correctly and push "Open" button to the ebd of card we have to let Card.Column component to get the full width */}
                                <Card.Column styles={{ width: '100%' }}>
                                    <Card.Body>
                                        <Flex space="between" vAlign="center">
                                            <Text content={cardData.AppName} weight="bold" size="large" />
                                            <Button
                                                content="Open"
                                                size="small"
                                            />
                                        </Flex>
                                    </Card.Body>
                                    <Card.Body>
                                        <Text content={cardData.Description} />
                                    </Card.Body>
                                    {/* <Card.Footer fitted>
                                    <Flex space="between" vAlign="center">
                                        <Text content="Category" />
                                        <Text timestamp content="Date" />
                                    </Flex>
                                </Card.Footer> */}
                                </Card.Column>
                            </Card>
                        </div>
                    )
                    )}
                </Grid>

            </>
        )
    }
}