import React from "react";
import { Welcome } from "./sample/Welcome";
import PowerAppsCards from "./sample/PowerAppsCards";

export default function Tab() {
  return (
    <div>
      {/* <Welcome /> */}
      <PowerAppsCards />
    </div>
  );
}
